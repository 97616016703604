<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Sospesi di Cassa"
    :route-father="routeFather"
  >
  
    <template v-slot:header-actions>
      <ExportExcelTable
        :worksheetName="worksheetName"
        :fileName="fileName"
        :fieldsToExport="fieldsToExport"
        :fetch="fetchExcel"
      > </ExportExcelTable>
    </template>
    <template v-slot:content>
      <div class="d-flex justify-center align-center mb-3" style="width: 100%; height: 15%;">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :filters="filterTypes"
          :advance-filters="advanceFilters"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-name="{filter}">
            Nome: {{ filter.value }}
          </template>

          <template v-slot:custom-status="{filter}">
            <v-row class="pa-6">
              <v-col>
                <v-select
                  v-model="filter.value"
                  :items="status"
                  item-text="label"
                  item-value="value"
                  label="Stato"
                  multiple
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </template>

          <template v-slot:custom-cashDeskOperator="{filter}">
            <v-row class="pa-2 py-4">
              <v-col>
                <OperatorsAutocomplete
                  v-model="filter.value"
                  :filled="false"
                  :multiple="false"
                  return-object
                  dense
                ></OperatorsAutocomplete>
              </v-col>
            </v-row>
          </template>

          <template v-slot:custom-serviceTypeCategory="{filter}">
            <v-row class="pa-6">
              <v-col>
                <v-autocomplete
                  v-model="filter.value"
                  itemText="name"
                  itemValue="id"
                  label="Reparto"
                  :items="serviceTypeCategoryItems"
                  dense
                  filled
                  rounded
                  hide-details="auto"
                  multiple
                ></v-autocomplete> 
              </v-col>
            </v-row>
          </template>
        </AdvancedFilter>
      </div>
      <div style="height: 83%; overflow-y: hidden;" ref="dataTableWrapper">
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento pagamenti sospesi ..."
          :headers="headers"
          :loading="loading"
          :items="filteredPaymentCredits"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          :show-actions="false"
          :show-select="false"
          @edit="handleEdit"
          @delete="handleDelete"
          @dblclick:row="payCredit"
        >
          <template v-slot:custom-amount="{item}">
            <span v-if="!!item.amount">
              {{Number(item.amount).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }).replaceAll(".", "'")}}
            </span>
            <span v-else>
              Non specificato
            </span>
          </template>
          <template v-slot:custom-customer="{item}">
            <span v-if="!!item.bills && item.bills.length > 0 && !!item.bills[0].billCustomers && item.bills[0].billCustomers.length > 0 && !!item.bills[0].billCustomers[0].customer">
              {{item.bills[0].billCustomers[0].customer.lastname}} {{item.bills[0].billCustomers[0].customer.firstname}}
            </span>
            <span v-if="!!item.schedulePayment">
              {{item.schedulePayment.customer.lastname}} {{item.schedulePayment.customer.firstname}}
            </span>
            <span v-if="!!item.paymentTransaction && !!item.paymentTransaction[0] && !!item.paymentTransaction[0].customerCards">
              {{item.paymentTransaction[0].customerCards.customer.lastname}} {{item.paymentTransaction[0].customerCards.customer.firstname}}
            </span>
          </template>
          <template v-slot:custom-status="{item}">
            <v-chip 
              v-if="item.status == 'beginning'" 
              color="error" 
              label
            >
              Non pagato
            </v-chip>
            <span 
              v-else-if="item.status == 'inProgress'"
              color="warning" 
              label
            >
              Pagato parzialmente
            </span>
            <v-chip
              v-else-if="item.status == 'finished'"
              color="success" 
              label
            >
              Pagato totalmente
            </v-chip>
          </template>
          <template v-slot:custom-operator="{item}">
            <span v-if="!!item.paymentTransaction && !!item.paymentTransaction[0] && !!item.paymentTransaction[0].paymentTransactionsCashDeskLogs">
              {{ item.paymentTransaction[0].paymentTransactionsCashDeskLogs.cashDeskLog.operator.firstname }} {{ item.paymentTransaction[0].paymentTransactionsCashDeskLogs.cashDeskLog.operator.lastname }}
            </span>
            <span v-else-if="!!item.bills && !!item.bills[0] && !!item.bills[0].paymentTransactions && !!item.bills[0].paymentTransactions[0] && !!item.bills[0].paymentTransactions[0].paymentTransactionsCashDeskLogs && !!item.bills[0].paymentTransactions[0].paymentTransactionsCashDeskLogs.cashDeskLog">
              {{ item.bills[0].paymentTransactions[0].paymentTransactionsCashDeskLogs.cashDeskLog.operator.firstname }} {{ item.bills[0].paymentTransactions[0].paymentTransactionsCashDeskLogs.cashDeskLog.operator.lastname }}
            </span>
            <span v-else-if="!!item.schedulePayment && !!item.schedulePayment.promoCustomer && !!item.schedulePayment.promoCustomer.paymentTransaction && !!item.schedulePayment.promoCustomer.paymentTransaction.paymentTransactionsCashDeskLogs && !!item.schedulePayment.promoCustomer.paymentTransaction.paymentTransactionsCashDeskLogs.cashDeskLog">
              {{ item.schedulePayment.promoCustomer.paymentTransaction.paymentTransactionsCashDeskLogs.cashDeskLog.operator.firstname }} {{ item.schedulePayment.promoCustomer.paymentTransaction.paymentTransactionsCashDeskLogs.cashDeskLog.operator.lastname }}
            </span>
          </template>
          <template v-slot:custom-serviceTypesCategory="{item}">
            <span>
              {{ getServiceTypeCategoryName(item) }}
            </span>
          </template>

          <template v-slot:externalFooter>
            <TypeDataTable
              :headers="[{ text: 'Totale Insoluti', value: 'totalAmount', type: 'cash' }]"
              :items="totalRow"
              :show-select="false"
              :show-actions="false"
              disablePagination
            >
            </TypeDataTable>
          </template>
        </TypeDataTable>
      </div>
    </template>
  </FullScreenDialog >
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import OperatorsAutocomplete from "@/components/common/OperatorsAutocomplete.vue";
import paymentCreditsService from '@/services/paymentCredits/paymentCredits.service.js'
import serviceTypesCategoriesService from '@/services/serviceTypesCategories/serviceTypesCategories.service.js'
import dateUtils from "@/mixins/common/dateUtils"


export default {
  name: "PaymentCreditsList",
  components: {
    FullScreenDialog,
    TypeDataTable,
    StandardMenu,
    AdvancedFilter,
    ExportExcelTable,
    OperatorsAutocomplete
  },
  mixins:[dateUtils],
  data: function() {
    let props = this.$router.resolve({name: 'Payments'});

    return {
      worksheetName: "",
      fileName:"",
      fieldsToExport: {},
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      paymentCredits: [],
      headers: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 0,
      totalRow: [],
      resizeListener: undefined,
      filterTypes: [
        { type: 'date', operator: 'between', field: 'openedAt', name: 'Data Inizio Debito', label: 'Del giorno', color: "", value: [undefined, undefined] },
        { type: 'date', operator: 'between', field: 'closedAt', name: 'Data Saldo Debito', label: 'Del giorno', color: "", value: [undefined, undefined] },
        { type: 'custom', operator: 'custom', field: 'status', name: 'Stato', label: 'Stato', color: "", value: [], icon: "mdi-list-status" },
      ],
      advanceFilters: [
        { type: 'cash', operator: 'equal', field: 'payment_credits.amount', name: 'Ammontare Iniziale', label: 'Uguale a', color: "", value: undefined },
        { type: 'custom', operator: 'custom', field: 'cashDeskOperator', name: 'Operatore Cassa', label: 'Operatore Cassa', value: undefined, icon: 'mdi-account-lock-outline' },
        { type: 'custom', operator: 'custom', field: 'serviceTypeCategory', name: 'Reparto', label: 'Reparto', value: [], icon: 'mdi-account-outline' },
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'name', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [
      ],
      defaultFilters:{ operator: 'in', field: 'payment_credits.type', value: ['cashDesk', 'customerCards_transaction']},
      status:[
        { value: 'beginning', label: 'Non pagato'},
        { value: 'inProgress', label: 'Pagato parzialmente'},
        { value: 'finished', label: 'Pagato totalmente'},
      ],
      serviceTypeCategoryItems: [],
    }
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    }
  },
  mounted: function() {
    this.fetchHeaders()
    this.filtersValue = this.filters
    this.fetchServiceTypeCategoryItems()
    this.fetchPaymentCredits()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight *0.87

    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight *0.87
    }
    this.resizeListener = this.resizeListener.bind(this)
    window.addEventListener('resize', this.resizeListener)
    this.fieldsToExport = paymentCreditsService._fieldsToExport();

    this.worksheetName = "Lista Sospesi di Cassa";
    this.fileName =
      "SospesidiCassa_" + this._buildDateToExport(new Date()) +
      ".xls";

  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    fetchPaymentCredits() {
      let filters = [...this.filtersValue, this.defaultFilters]
      this.loadingItems = true
      paymentCreditsService.list(this.page, this.rowPerPage, filters).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page && this.totalPages != 0) {
          this.page = this.totalPages
        }
       

        this.paymentCredits = results.rows
        this.totalRow = [results.totalRows]
        this.loadingItems = false
      })
    },
    fetchHeaders() {
      this.loadingHeaders = true
      paymentCreditsService.fields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    fetchServiceTypeCategoryItems() {
      serviceTypesCategoriesService.list().then((results) => {
        this.serviceTypeCategoryItems = results.rows
      })
    },
    getServiceTypeCategoryName(item){
      if(!item.bills[0])
        return 'Senza Reparto'
      let allServiceTypeCategories = item.bills[0].billServices.map(bs => bs.service.serviceType.serviceTypesCategory)
      let uniqueServiceTypeCategories = [...new Set(allServiceTypeCategories.map((utc) => {
          if (utc == null || utc == undefined)
              return 'Senza Reparto'
          else
            return utc.name
        })
      )]
      return uniqueServiceTypeCategories.join(', ')
    },
    applyFilters(filters){
      this.fetchPaymentCredits()
    },
    handleEdit(paymentCredit) {
      this.$router.push({
        name: 'PaymentCreditsEditForm',
        params: {
          id: paymentCredit.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'PaymentCreditsList'}).href,
          pathName: "PaymentCreditsList"
        }
      })
    },
    handleDelete(paymentCredit) {
      let confirmed = confirm('Sei sicuro di voler eliminare ' + paymentCredit.code + ' ?')

      if(confirmed) {
        this.loading = true
        paymentCreditsService.delete(paymentCredit).then(() => {
          this.fetchPaymentCredits()
          this.loading = false
        })
      }
    },
    goToNew() {
      this.$router.push({
        name: 'PaymentCreditsNewForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'PaymentCreditsList'}).href
        }
      })
    },
    payCredit(row, {item: paymentCredit}) {
      if(paymentCredit.status == "beginning") {
        this.$router.push({
          name: 'PayPaymentCredit',
          params: {
            paymentCredit: paymentCredit,
            paymentCreditId: paymentCredit.id,
            customer: !!paymentCredit.bills[0] ? paymentCredit.bills[0].billCustomers[0].customer : 
              !!paymentCredit.paymentTransaction[0] ? paymentCredit.paymentTransaction[0].customerCards.customer : undefined,
            pathToGoBack: "PaymentCreditsList",
            pathName: "PaymentCreditsList",
            filters: this.filtersValue,
          }
        })
      } else {
        this.$delegates.snackbar("Già pagato");
      }
    },
    async fetchExcel(){
      let res = await paymentCreditsService.list(undefined, 5000, [...this.filtersValue,this.defaultFilters])
        res.rows.map(p => {
          if(!!p.bills && p.bills.length > 0 && !!p.bills[0].billCustomers && p.bills[0].billCustomers.length > 0 && !!p.bills[0].billCustomers[0].customer)
              p.fullname = p.bills[0].billCustomers[0].customer.lastname + ' ' + p.bills[0].billCustomers[0].customer.firstname
          if(!!p.schedulePayment)
              p.fullname = p.schedulePayment.customer.lastname + ' ' + p.schedulePayment.customer.firstname
          if(!!p.paymentTransaction && !!p.paymentTransaction[0] && !!p.paymentTransaction[0].customerCards)
              p.fullname = p.paymentTransaction[0].customerCards.customer.lastname + ' ' + p.paymentTransaction[0].customerCards.customer.firstname
        })
      return res.rows
    },
  },
  computed: {
    filteredPaymentCredits() {
      return this.paymentCredits
    },
    loading() {
      return this.loadingItems || this.loadingHeaders
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    }
  },
  watch: {
    page() {
      this.fetchPaymentCredits()
    },
    rowPerPage() {
      this.fetchPaymentCredits()
    },
  }
}
</script>